<template>
  <div>
    <v-dialog v-model="openModal" max-width="600" hide-overlay>
      <v-toolbar dark color="secondary">
        <v-btn icon dark @click="openModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Скачать подписанные документы</v-toolbar-title>
      </v-toolbar>

      <v-card class="pa-10">
        <div class="item__row item__ac mb-4 pointer">
          <i class="mdi mdi-cloud-upload-outline mr-2"></i>
          <p
            class="mb-0"
            @click="$parent.downloadContract(contract, contract.id)"
            v-if="contract.type"
          >
            Скачать {{ contract.type.full_name_ru }}
          </p>
        </div>
        <div v-for="item in contract.attachments" :key="item.id">
          <div class="item__row item__ac pointer mb-4">
            <i class="mdi mdi-cloud-upload-outline mr-2"></i>
            <p
              class="mb-0"
              @click="$parent.downloadAttachment(item, item.id)"
              v-if="item.type"
            >
              Скачать {{ item.type.title }}
            </p>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["contract"],
  components: {},
  data() {
    return {
      openModal: false,
    };
  },
  methods: {},
  mounted() {},
  watch: {},
};
</script>
