<template>
  <div class="section__document__pdf pa-3">
    <div class="section__line" ref="section__line">
      <div class="section__bg section--bank__info section--bank__data">
        <div class="section__title--block section__title--margin">
          <div class="section__title">Паспорт документа</div>
        </div>

        <table class="style__table" v-if="passports">
          <tbody class="style__table--tbody">
            <tr>
              <th style="width: 20%"><span>Документ</span></th>
              <td style="width: 80%">
                <p>
                  <strong>Категория:</strong> Внешний документ <br />
                  <strong> Статус документа:</strong> Подписан <br />
                </p>
                <p v-if="passports[0].document_client">
                  <strong>Договор : </strong> № {{ passports[0].document_client.id }}
                </p>
                <p v-if="passports[0].attachment_client">
                  <strong> Приложение </strong>№ {{ passports[0].attachment_client.id }}
                </p>
                <p v-if="passports && passports[0].document_client">
                  <strong> Название контракта:</strong>
                  {{ passports[0].document_client.type.full_name_ru }}
                </p>
                <p v-if="passports && passports[0].attachment_client">
                  <strong> Название приложений :</strong>
                  {{ passports[0].attachment_client.type.title }}
                </p>
              </td>
            </tr>
            <tr v-for="sender in passports" :key="sender.id">
              <th style="width: 20%" v-if="sender.isAdmin"><span>Получатель</span></th>
              <th style="width: 20%" v-else><span>Отправитель</span></th>
              <td style="width: 80%">
                <p>{{ sender.owner_cert }}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="section__title--block section__title--margin section__line--top">
          <div class="section__title mt-3">Детали подписи документа</div>
        </div>

        <div class="passport__text" v-for="(detail, index) in passports" :key="index">
          <p v-if="!detail.isAdmin">
            <strong>Данные по отправителю: </strong>{{ detail.organization }}
          </p>
          <p v-else><strong>Данные по получателю: </strong>{{ detail.organization }}</p>
          <p>
            <strong>Подписант № {{ index + 1 }}</strong
            ><br />
            <strong>Владелец сертификата: </strong>{{ detail.owner_cert }}<br />
            <strong>Должность: </strong>
            <span v-if="!detail.isAdmin && detail.user_client.role">{{
              detail.user_client.role.name_ru
            }}</span>
            <span v-if="detail.isAdmin && detail.user_admin.role">{{
              detail.user_admin.role.name_ru
            }}</span>
            <br />
            <!-- <strong>Вид подписи: </strong> Сдал (Исполнитель)<br> -->
            <strong>Издатель: </strong> {{ detail.name_certificate }}<br />
            <strong>Серийный номер сертификата: </strong>{{ detail.seria_number }}<br />
            <strong>Дата и время подписания: </strong> {{ detail.date_sign }}<br />
            <strong>На момент подписания: </strong> Сертификат действителен и проверен НУЦ
            РК<br />
            <strong>Хэш подписи: </strong>
            {{ detail.hash_sign.substring(0, 20) + "...." }}
          </p>
        </div>
      </div>
    </div>

    <button class="sign__button pointer" @click="downloadPdf()">
      <i class="mdi mdi-upload mr-1" style="color: white"></i>
      Скачать
    </button>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  props: ["passports"],
  components: {},
  data() {
    return {
      contract: [],
    };
  },
  methods: {
    downloadPdf() {
      html2pdf(this.$refs.section__line, {
        margin: 1,
        filename: "document.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      });
    },
    getDocument() {
      this.$axios({
        method: "get",
        url:
          this.$API_URL +
          this.$API_VERSION +
          "admin/document?id=" +
          this.$route.params.id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.contract = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    // getPassport() {

    //     this.$axios({
    //         method: 'get',
    //         url: this.$API_URL + this.$API_VERSION + 'passport?id='+this.$route.params.id,
    //         headers: {
    //             'Authorization': `Bearer ${localStorage.getItem('access_token_kcmr')}`
    //         }
    //     })
    //     .then((response) => {
    //         console.log(response.data);

    //         this.passports = response.data;
    //     })
    //     .catch((error) => {
    //         console.warn(error);
    //     });
    // },
  },
  mounted() {
    this.getDocument();
  },
  watch: {},
};
</script>
